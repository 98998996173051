import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/footer';
import Scrollbar from '../../../components/scrollbar';
import Services from "./component/Content";
import PageTitle from './component/PageHeader';

const PlatformElasticPage =() => {
    return(
        <Fragment>
            <Helmet>
                <title>Plataforma - Elastic Stack</title>
                <meta name="description" content="Desbloqueie o potencial da sua plataforma com Elastic Stack. Análise, visualize e aprimore seus dados de maneira escalável." />
                <meta name="keywords" content="sre, devops, observability, kubernetes, filebeat, kibana, elasticsearch, elastic apm, aws, gce, oci, azure" />
                <meta name="author" content="" />
                <meta property="og:title" content="O poder da engenharia de plataforma" />
                <meta property="og:description" content="Automatize processos e maximize a eficiência operacional." />
            </Helmet>            
            <Navbar/>
            <PageTitle pageTitle={'Elastic Stack as a Services'} pagesub={'platform/elasticstack'}/> 
            <Services srvClass={'wpo-service-section-s3'}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default PlatformElasticPage;
