import React from 'react'
import ssimg2 from '../../../../images/service-single/k8s-430x320-1.png'
import ssimg3 from '../../../../images/service-single/k8s-v2.png'
import ssimg from '../../../../images/service-single/k8s_v1.png'
import Benefits from './benefits'
import ServiceSingleSidebar from './sidebar'
import Solutions from './solution'


const Services = (props) => {
    return(
        <section className="wpo-service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="wpo-service-single-wrap">
                            <div className="wpo-service-single-content">
                                <img src={ssimg} alt=""/>
                                <div className="wpo-service-single-content-des">
                                    <h2>Crie, execute e gerencie aplicativos na nuvem com Kubernetes</h2>
                                    <p>Como seu parceiro de confiança para a gestão de Clusters Kubernetes, a CodeView Consultoria está aqui para auxiliar as empresas a enfrentarem desafios de implantações em grande escala, enquanto otimizam os custos operacionais. A CodeView é uma empresa especializada em serviços de implementação de Kubernetes, dedicada a auxiliar as empresas de todos os portes a alcançarem seus objetivos de negócios, modernizando sua infraestrutura na nuvem.</p>
                                    <p>Quando se trata de executar aplicativos de missão crítica na nuvem, ter um parceiro confiável é fundamental. É por isso que a CodeView Consultoria, especializada em serviços de gerenciamento de serviços em nuvem, está aqui para garantir a confiabilidade das operações, bem como manter os mais altos padrões de conformidade e segurança em todos os aspectos. Conte conosco para a excelência em serviços na nuvem.</p>
                                    <div className="wpo-service-single-sub-img">
                                        <ul>
                                            <li><img src={ssimg2} alt=""/></li>
                                            <li><img src={ssimg3} alt=""/></li>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                            <Solutions/>
                            <Benefits/>
                        </div>
                    </div>
                    <ServiceSingleSidebar/>
                    
                </div>
            </div>
        </section>
    )
}

export default Services;