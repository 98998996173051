import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../../components/Navbar';
import Support from '../../../components/Support';
import Footer from '../../../components/footer';
import Scrollbar from '../../../components/scrollbar';
import Services from "./components/Content";
import PageTitle from './components/PageHeader';

const ConsultingMigrationPage =() => {
    return(
        <Fragment>
            <Helmet>
                <title>Migração ambientes Cloud e On premises</title>
                <meta name="description" content="Facilite sua migração para a nuvem com nossa expertise. Soluções eficientes para uma transição suave e sem complicações." />
                <meta name="keywords" content="sre, devops, observability, kubernetes, automação, pipelines, CI/CD, finops, aws, gce, oci, azure" />
                <meta name="author" content="" />
                <meta property="og:title" content="O poder da engenharia de plataforma" />
                <meta property="og:description" content="Automatize processos e maximize a eficiência operacional." />
            </Helmet>             
            <Navbar/>
            <PageTitle pageTitle={'Cloud Migration'} pagesub={'consultoria-ti/migracao'}/> 
            <Services srvClass={'wpo-service-section-s3'}/>
            <Support/>
            <br></br>
            <br></br>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ConsultingMigrationPage;
