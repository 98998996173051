import React, { Component } from "react";
import { Link } from "react-router-dom";
import HeaderTopbar from "../../components/HeaderTopbar";
import MobileMenu from "../../components/MobileMenu";
import Logo from "../../images/cdn/logos/codeview.png";


export default class Header extends Component {

  render() {
    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };
    return (
      <header id="header">
        <HeaderTopbar />
        <div className="wpo-site-header">
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    <Link
                      onClick={ClickHandler}
                      className="navbar-brand"
                      to="/"
                    >
                      <img src={Logo} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-md-1 col-1">
                  <div
                    id="navbar"
                    className="collapse navbar-collapse navigation-holder"
                  >
                    <button className="menu-close">
                      <i className="ti-close"></i>
                    </button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="/">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link onClick={ClickHandler} to="/sobre">
                          Quem somos
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="#">
                          Consultoria
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link onClick={ClickHandler} to="/consultoria-ti/migracao">
                              Cloud Migration
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/consultoria-ti/finops">
                              Cloud FinOps
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/consultoria-ti/seguranca">
                              Cloud Security
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/consultoria-ti/alocacao-de-profissionais">
                             Teams
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="#">
                          Serviços
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link onClick={ClickHandler} to="/servicos-ti/kubernetes">
                              Kubernetes
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/servicos-ti/observabilidade">
                              Observability
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/servicos-ti/devops">
                              DevOps Automation
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="#">
                          Plataformas
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link onClick={ClickHandler} to="/plataformas/prometheus-stack-como-servico">
                              PrometheusStack - CVPS
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/plataformas/elastic-stack-servico">
                              ElasticStack - CVES
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/plataformas/kubernetes-servico-gerenciado">
                              Kubernetes - CVKS
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link onClick={ClickHandler} to="/contato">
                          Contato
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-2 col-2">
                  <div className="header-right">
                    <div className="close-form">
                      <Link
                        onClick={ClickHandler}
                        className="theme-btn"
                        to="/contato"
                      >
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}
