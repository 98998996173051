import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/footer';
import Scrollbar from '../../../components/scrollbar';
import Services from "./component/Content";
import PageTitle from './component/PageHeader';

const PlatformPrometheusPage =() => {

    return(
        <Fragment>
            <Helmet>
                <title>Plataforma - Prometheus Stack</title>
                <meta name="description" content="Otimize seu monitoramento com Prometheus Stack. Obtenha insights precisos e tome decisões informadas. Descubra mais sobre nossa plataforma agora!" />
                <meta name="keywords" content="sre, devops, observability, kubernetes, metricas, golden signals, grafana, thanos, prometheus, aws, gce, oci, azure" />
                <meta name="author" content="" />
                <meta property="og:title" content="O poder da engenharia de plataforma" />
                <meta property="og:description" content="Automatize processos e maximize a eficiência operacional." />
            </Helmet>             
            <Navbar/>
            <PageTitle pageTitle={'Prometheus as a services'} pagesub={'platform/prometheus'}/> 
            <Services srvClass={'wpo-service-section-s3'}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default PlatformPrometheusPage;
