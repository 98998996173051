import React from 'react';
import { Link } from 'react-router-dom';

const Support = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <section className="wpo-support-section">
            <div className="container">
                <div className="wpo-support-wrapper">
                    <div className="wpo-support-text">
                        <h2>Consultoria para seu negócio ?</h2>
                        <p>Agende uma conversa com um de nossos especialistas, estamos prontos para lhe apoiar na jornada de inovação com soluções e metodologias que fazem sentido para seu negócio.</p>
                    </div>
                    <div className="wpo-support-btn">
                        <Link onClick={ClickHandler} to={{ pathname: "https://calendly.com/codeview"}} target="_blank" rel="noopener noreferrer">Agende agora</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Support;