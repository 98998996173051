import React from 'react'
import { Link } from 'react-router-dom'

import srvimg2 from '../../../../images/icon/briefcase.svg'
import srvimg3 from '../../../../images/icon/chart.svg'
import srvimg1 from '../../../../images/icon/clipboard.svg'


const Services  = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

     
     const service = [
         { 
            sIcon:srvimg1,
            title:'Staff Engineer',
            des:'Identificamos profissionais altamente qualificados que se encaixam perfeitamente nas necessidades específicas da sua empresa. Seja para liderar projetos críticos, fornecer orientação técnica ou capacitar sua equipe, nossos Staff Engineers estão prontos para assumir o desafio.',      
            link:'/#',
         },
         {
            sIcon:srvimg2,
            title:'Senior Staff Engineer',
            des:'Transforme sua equipe técnica com os melhores talentos Senior Staff Engineer. Oferecemos soluções personalizadas para liderar projetos críticos, impulsionar a inovação e alcançar o sucesso. Encontre o parceiro ideal para o crescimento da sua empresa. Entre em contato agora.',      
            link:'/#',
         },
         {
            sIcon:srvimg3,
            title:'Principal Engineer',
            des:'Eleve sua equipe com os melhores talentos em engenharia de software. Nossos Principal Software Engineers trazem liderança técnica e expertise para impulsionar a inovação e a excelência em seus projetos mais críticos. Descubra como podemos ajudar a impulsionar o sucesso de sua empresa.',      
            link:'/#',
         },
         
     ]


    return(
        <section className="wpo-service-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            <span>De enhenheiro para engenheiro</span>
                            <h2>Eleve o nível de seus times</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {service.map((service, sitem) => (
                        <div className="col-lg-4 col-md-6 col-12" key={sitem}>
                            <div className="wpo-service-item">
                                <div className="wpo-service-icon">
                                    <div className="icon">
                                        <img src={service.sIcon} alt=""/>
                                    </div>
                                </div>
                                <div className="wpo-service-text">
                                    <h2><Link onClick={ClickHandler} to={service.link}>{service.title}</Link></h2>
                                    <p>{service.des}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Services;