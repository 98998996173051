import React from 'react'
import ssimg from '../../../../images/service-single/devops-img-1.jpg'
import ssimg2 from '../../../../images/service-single/devops-img-2.jpg'
import ssimg3 from '../../../../images/service-single/devops-img-3.jpg'
import Benefits from './benefits'
import ServiceSingleSidebar from './sidebar'
import Solutions from './solution'


const Services = (props) => {
    return(
        <section className="wpo-service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="wpo-service-single-wrap">
                            <div className="wpo-service-single-content">
                                <img src={ssimg} alt=""/>
                                <div className="wpo-service-single-content-des">
                                    <h2>Ajudando você em cada etapa da implementação do DevOps</h2>
                                    <p>No cenário atual, a agilidade e eficiência no desenvolvimento e na entrega de software são fundamentais para o sucesso de qualquer empresa de tecnologia. É aqui que entra o DevOps, uma abordagem revolucionária que visa eliminar barreiras entre equipes de desenvolvimento e operações, promovendo integração contínua, entrega contínua, automação e colaboração. Porém, implementar com sucesso o DevOps não é uma tarefa simples. É um processo que envolve várias etapas e desafios, mas também oferece recompensas substanciais em termos de qualidade, velocidade e eficiência.</p>
                                    <p>Na CodeView Consultoria, estamos comprometidos em ajudar sua organização em cada etapa da implementação do DevOps. Quer você esteja dando os primeiros passos na jornada DevOps ou procurando otimizar processos já existentes, nossa equipe de especialistas está pronta para auxiliá-lo em todos os aspectos desse caminho transformador.
</p>
                                    <div className="wpo-service-single-sub-img">
                                        <ul>
                                            <li><img src={ssimg2} alt=""/></li>
                                            <li><img src={ssimg3} alt=""/></li>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                            <Solutions/>
                            <Benefits/>
                        </div>
                    </div>
                    <ServiceSingleSidebar/>
                    
                </div>
            </div>
        </section>
    )
}

export default Services;