import React from "react";
import { Link } from "react-router-dom";

import prjImg1 from "../../../../images/project-single/elk-img-1.jpg";
import prjImg2 from "../../../../images/project-single/elk-img-2.jpg";
import prjImg3 from "../../../../images/project-single/elk-img-3.jpg";

import Support from "../../../../components/Support";
import prIcon2 from "../../../../images/icon/bar-graph.svg";
import prIcon3 from "../../../../images/icon/clipboard.svg";
import prIcon1 from "../../../../images/icon/document.svg";

const Services = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-project-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="wpo-project-single-wrap">
              <div className="wpo-project-single-content">
                <img src={prjImg1} alt="" />
                <div className="wpo-project-single-content-des">
                  <div className="wpo-project-single-content-des-wrap">
                    <div className="wpo-project-single-content-des-left">
                      <h2>O que é o Elastic Stack?</h2>
                      <p>
                        O Elastic Stack é uma suíte de software de código aberto
                        amplamente usada para coleta, armazenamento, pesquisa e
                        visualização de dados. Essa suíte é composta por quatro
                        componentes principais, sendo “Elasticsearch, Kibana,
                        Logstash e Beats”: Uma coleção de agentes leves que
                        auxiliam na coleta de dados de diversas fontes, como
                        logs, métricas de sistemas e serviços em execução.
                      </p>
                      <p>
                        O Elastic Stack é altamente escalável e adequado para
                        ambientes de grande escala, sendo frequentemente
                        utilizado para monitoramento de sistemas, análise de
                        logs, pesquisa de texto e análise de métricas. Ele é uma
                        escolha popular entre organizações que desejam obter
                        insights de dados em tempo real e resolver problemas
                        operacionais de forma eficaz.
                      </p>
                      <p>
                        Além disso, o Elastic Stack é conhecido por sua
                        flexibilidade e facilidade de integração com uma
                        variedade de tecnologias e ferramentas, tornando-o uma
                        suíte de software versátil para uma ampla gama de casos
                        de uso em análise de dados e monitoramento. É amplamente
                        utilizado em ambientes de DevOps, análise de segurança,
                        observabilidade de aplicativos e muito mais.
                      </p>
                    </div>
                    <div className="wpo-project-single-content-des-right">
                      <ul>
                        <li>
                          Name:<span>Elastic stack</span>
                        </li>
                        <li>
                          License :<span>Free/OpenSource</span>
                        </li>
                        <li>
                          Source :
                          <span>
                            <Link href="https://github.com/elastic">
                              Github
                            </Link>
                          </span>
                        </li>
                        <li>
                          Doc:{" "}
                          <span>
                            {" "}
                            <Link href="https://www.elastic.co/guide/index.html">
                              Oficial
                            </Link>
                          </span>
                        </li>
                        <li>
                          Tag :<span>observability, apm, logging, metrics</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="wpo-project-single-sub-img">
                    <ul>
                      <li>
                        <img src={prjImg2} alt="" />
                      </li>
                      <li>
                        <img src={prjImg3} alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="wpo-solutions-section">
                <h2>Benefícios</h2>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="wpo-solutions-item">
                      <div className="wpo-solutions-icon">
                        <div className="icon">
                          <img src={prIcon1} alt="" />
                        </div>
                      </div>
                      <div className="wpo-solutions-text">
                        <h2>
                          <Link onClick={ClickHandler} to="/#">
                            Simplicidade de escala
                          </Link>
                        </h2>
                        <p>
                          Com o Elastic Stack como um serviço, você pode
                          dimensionar seus recursos de acordo com as demandas do
                          seu negócio, sem a preocupação de gerenciar
                          infraestrutura.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="wpo-solutions-item">
                      <div className="wpo-solutions-icon">
                        <div className="icon">
                          <img src={prIcon2} alt="" />
                        </div>
                      </div>
                      <div className="wpo-solutions-text">
                        <h2>
                          <Link onClick={ClickHandler} to="/#">
                            Buscas Poderosas
                          </Link>
                        </h2>
                        <p>
                          O Elasticsearch oferece uma pesquisa poderosa e rápida
                          que permite encontrar informações críticas dentro de
                          grandes conjuntos de dados. Com nosso serviço a
                          padronização dos logs e eventos são essenciais{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="wpo-solutions-item">
                      <div className="wpo-solutions-icon">
                        <div className="icon">
                          <img src={prIcon3} alt="" />
                        </div>
                      </div>
                      <div className="wpo-solutions-text">
                        <h2>
                          <Link onClick={ClickHandler} to="/#">
                            Suporte Profissional
                          </Link>
                        </h2>
                        <p>
                          Nossa equipe de especialistas em Elastic Stack está à
                          disposição para fornecer suporte técnico e
                          orientações, garantindo que você aproveite ao máximo a
                          suíte de software.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h2>Descubra o Poder do Elastic Stack como um Serviço</h2>
                <p>
                  No mundo da tecnologia, a capacidade de analisar, visualizar e
                  extrair insights valiosos dos seus dados é fundamental para o
                  sucesso dos negócios. É aqui que o Elastic Stack como um
                  serviço entra em cena, fornecendo uma solução poderosa e
                  eficiente para empresas que desejam aproveitar ao máximo seus
                  dados. Na CodeView Consultoria, oferecemos o Elastic Stack
                  como um serviço para ajudar a transformar seus dados em
                  informações valiosas.
                </p>
                <p>
                  Organizações de todos os tamanhos utilizam o Elasticsearch por
                  sua flexibilidade, eficácia e poder como plataforma de
                  pesquisa. O OpenSource Connections ajuda nossos clientes a
                  aproveitar o Elasticsearch para avançar em seus esforços de
                  pesquisa e, de forma mais ampla, em seus objetivos
                  organizacionais, que frequentemente incluem impulsionar
                  vendas, melhorar a experiência do usuário, apoiar lançamentos
                  de produtos ou refinar a coleta de conteúdo. Se precisar de
                  ajuda com o Elasticsearch, sinta-se avontade para agendar com um de nossos especialistas.
                </p>
              </div>
              <br></br>
              <Support fnClass={"section-padding"} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
