import React from 'react'
import { Link } from 'react-router-dom'
import spimg1 from '../../../images/ab-shape-1.png'
import spimg2 from '../../../images/ab-shape-2.png'
import spicon from '../../../images/cdn/home/api-monetization-svgrepo-com.svg'
import abimg from '../../../images/cdn/home/img-home-1.jpg'

const Texts = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <section className={`wpo-about-section ${props.abClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                        <div className="wpo-about-img">
                            <img src={abimg} alt=""/>
                            <div className="wpo-ab-shape-1"><img src={spimg1} alt=""/></div>
                            <div className="wpo-ab-shape-2"><img src={spimg2} alt=""/></div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                        <div className="wpo-about-icon">
                            <div className="icon">
                                <img src={spicon} alt=""/>
                            </div>
                        </div>
                        <div className="wpo-about-icon-content">
                            <h2>Porque escolher a <span>CodeView</span> Consultoria</h2>
                            <p>Apoiamos uma cultura DevOps robusta, integrando engenharia de plataforma para potencializar resultados contínuos e impulsionar produtividade. Modernizamos a infraestrutura em cloud ou oprimisse, criando ferramentas para entrega contínua e desenvolvimento ágil. Nosso compromisso vai além das tecnologias; priorizamos pessoas e processos, garantindo uma jornada transparente, excelência nas entregas e os melhores resultados."</p>
                            <div className="signeture">

                            </div>
                            <Link onClick={ClickHandler} to="/contato" className="btn theme-btn" >Fale conosco</Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Texts;