import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

class Services extends Component {
  
  render() {
    var settings = {
      dots: true,
      arrows: true,
      speed: 3000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };

    const service = [
      {
        sIcon: "fi flaticon-business",
        title: "Cloud Services",
        des: "Trabalhamos juntamente com nossos clientes para obter a melhor estratégia para a nuvem, seja ela pública, privada ou híbrida.",
        link: "/#",
      },
      {
        sIcon: "fi flaticon-edit",
        title: "DevOps/SRE",
        des: "DevOps e SRE tornam os processos digitais mais rápidos e uma infraestrutura de TI confiável. Desbloqueie todo o seu potencial.",
        link: "/#",
      },
      {
        sIcon: "fi flaticon-magnifiying-glass",
        title: "Plaftorms",
        des: "Reinvente soluções internas de desenvolvimento e operações e aumente a produtividade de suas equipes no desenvolvimento de software.",
        link: "/#",
      },
      {
        sIcon: "fi flaticon-startup",
        title: "Kubernetes",
        des: "Acelere seus projetos locais e remotos, mesmo os desafios mais difíceis, como armazenamento persistente e cluster auto gerenciado.",
        link: "/#",
      },
      {
        sIcon: "fi flaticon-trophy",
        title: "Agile Teams",
        des: "Formas ágeis de trabalhar. Acreditamos cada vez mais que o fator de sucesso crítico mais importante para uma equipe eficaz é a colocação.",
        link: "/#",
      },
    ];
    return (
      <section
        className={`wpo-service-section section-padding ${this.props.srvClass}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="wpo-section-title">
                <span>Conheça mais sobre</span>
                <h2>Serviços e Soluções</h2>
              </div>
            </div>
          </div>
          <div className="wpo-service-items wpo-service-slider owl-carousel">
            <Slider {...settings}>
              {service.map((service, sitem) => (
                <div className="col-lg-4 col-md-6 col-12" key={sitem}>
                  <div className="wpo-service-item">
                    <div className="wpo-service-icon">
                      <div className="icon">
                        <i className={service.sIcon}></i>
                      </div>
                    </div>
                    <div className="wpo-service-text">
                      <h2>
                        <Link onClick={ClickHandler} to={service.link}>
                          {service.title}
                        </Link>
                      </h2>
                      <p>{service.des}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}

export default Services;
