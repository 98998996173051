import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../../components/Navbar';
import Support from '../../../components/Support';
import Footer from '../../../components/footer';
import Scrollbar from '../../../components/scrollbar';
import Services from "./component/Content";
import PageTitle from './component/PageHeader';

const ServiceObserverPage =() => {

    return(
        <Fragment>
            <Helmet>
                <title>Observabilidade</title>
                <meta name="description" content="Aumente a visibilidade do seu sistema com nosso serviço de observabilidade. Monitore, analise e otimize seu desempenho de forma eficaz. Saiba mais agora" />
                <meta name="keywords" content="sre, devops, observability, kubernetes, ansible, configuration manager, aws, gce, oci, azure" />
                <meta name="author" content="" />
                <meta property="og:title" content="O poder da engenharia de plataforma" />
                <meta property="og:description" content="Automatize processos e maximize a eficiência operacional." />
            </Helmet>             
            <Navbar/>
            <PageTitle pageTitle={'Observabilidade e Telemetria'} pagesub={'services-it/observabilidade'}/> 
            <Services srvClass={'wpo-service-section-s3'}/>
            <br></br>
            <br></br> 
            <Support fnClass={'section-padding'}/>
            <br></br>
            <br></br> 
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ServiceObserverPage;


