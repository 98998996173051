import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/cdn/logos/logo-write.png";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <Link onClick={ClickHandler} to="/">
                    <img src={Logo} alt="blog" />
                  </Link>
                </div>
                <p>
                  Crescimento e eficiência em operações de TI é o nosso forte,
                  nosso compromisso vai além de contratos atuamos como parte da
                  solução.
                </p>
                <ul>
                  <li> 
                    <Link onClick={ClickHandler} to={{pathname:`https://www.facebook.com/codeviewconsultoria/`}} target="_blank">
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={{pathname:`https://twitter.com/codeviewdevops`}} target="_blank">
                      <i className="ti-twitter-alt"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={{pathname:`https://www.instagram.com/codeviewconsultoria/`}} target="_blank">
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={{pathname:`https://www.linkedin.com/company/codeviewconsultoria/`}} target="_blank">
                      <i className="ti-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contato </h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-location"></i>Rua Barão de
                      Jundiaí, 523 - Lapa, São Paulo - SP, 05073-010
                    </li>
                    <li>
                      <i className="fi flaticon-phone-call"></i>(11) 94050-1851
                    </li>
                    <li>
                      <i className="fi flaticon-send"></i>
                      contato@codeview.com.br
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Serviços</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/consultoria-ti/alocacao-de-profissionais">
                      Agile Teams
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/servicos-ti/devops">
                      DevOps/Sre
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/consultoria-ti/finops">
                     Cloud FinOps
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/consultoria-ti/migracao">
                      Cloud Management
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/plataformas/kubernetes-servico-gerenciado">
                      Kubernetes as a service
                    </Link>
                  </li>

                </ul>
              </div>
            </div>

            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>OpenSource </h3>
                </div>
                <ul>
                <li>
                    <Link onClick={ClickHandler} to={{pathname:`https://backstage.io/`}} target="_blank">
                      Backstage
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={{pathname:`https://www.elastic.co/elastic-stack`}} target="_blank">
                      Elastic Stack
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={{pathname:`https://kubernetes.io/pt-br/`}} target="_blank">
                      Kubernetes
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={{pathname:`https://prometheus.io/`}} target="_blank">
                      Prometheus
                    </Link>
                  </li>                  
                  <li>
                    <Link onClick={ClickHandler} to={{pathname:`https://opentelemetry.io/`}} target="_blank">
                      OpenTelemetry
                    </Link>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                {" "}
                Copyright &copy; 2024 <Link onClick={ClickHandler} to="/">
                CodeView Consultoria
                </Link>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
