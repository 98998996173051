import React from 'react'
import { Link } from 'react-router-dom'

import prjImg1 from '../../../../images/project-single/k8s-img-1.jpg'
import prjImg2 from '../../../../images/project-single/k8s-img-2.jpg'
import prjImg3 from '../../../../images/project-single/k8s-img-3.jpg'

import Support from '../../../../components/Support'
import prIcon2 from '../../../../images/icon/bar-graph.svg'
import prIcon3 from '../../../../images/icon/clipboard.svg'
import prIcon1 from '../../../../images/icon/document.svg'



const Services = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="wpo-project-single-section section-padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <div className="wpo-project-single-wrap">
                        <div className="wpo-project-single-content">
                            <img src={prjImg1} alt=""/>
                            <div className="wpo-project-single-content-des">
                                <div className="wpo-project-single-content-des-wrap">
                                    <div className="wpo-project-single-content-des-left">
                                        <h2>Modernização de aplicativos com serviços Kubernetes gerenciados</h2>
                                        <p>A modernização de aplicações desempenha um papel central na jornada em direção à nuvem e à transformação digital. Em um mercado global que movimenta centenas de bilhões de dólares, as aplicações empresariais são o cerne das operações diárias das empresas. Sem a modernização das aplicações e de sua infraestrutura subjacente, uma empresa está fadada a enfrentar desafios significativos em sua busca pela evolução digital. Isso é especialmente verdadeiro ao buscar aproveitar os amplos benefícios da computação em nuvem e da inteligência artificial em grande escala.</p>
                                        <p>Nossa abordagem, baseada em metodologia de microsserviços e suportada por clusters e contêineres Kubernetes, é a solução líder para superar os desafios tecnológicos da atualidade. Como um pilar fundamental da revolução DevOps, que promove a colaboração eficaz entre equipes de desenvolvimento e operações, nosso processo consiste em decompor a arquitetura do seu aplicativo em serviços especializados, módulos ágeis, implantados em contêineres empacotados.</p>
                                        <p>Esses contêineres abrigam o código de microsserviço e todos os requisitos de hardware e software necessários para operar de maneira autônoma e eficiente. Na CodeView, estamos comprometidos em oferecer soluções que impulsionam a sua transformação digital, proporcionando maior agilidade, escalabilidade e eficiência operacional.</p>

                                    </div>
                                    <div className="wpo-project-single-content-des-right">
                                    <ul>
                                            <li>Name:<span>Kubernetes</span></li>
                                            <li>License :<span>OpenSource</span></li>
                                            <li>Source :<span><Link href="https://github.com/kubernetes/kubernetes">Github</Link></span></li>
                                            <li>Doc: <span> <Link href="https://kubernetes.io/docs//">Oficial</Link></span></li>
                                            <li>Tag :<span>orquestration, containers</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wpo-project-single-sub-img">
                                    <ul>
                                        <li><img src={prjImg2} alt=""/></li>
                                        <li><img src={prjImg3} alt=""/></li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="wpo-solutions-section">
                            <h2>Benefícios</h2>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="wpo-solutions-item">
                                        <div className="wpo-solutions-icon">
                                            <div className="icon">
                                                <img src={prIcon1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wpo-solutions-text">
                                            <h2><Link onClick={ClickHandler} to="/#">Otimização de desempenho</Link></h2>
                                            <p>Dimensione contêineres de aplicativos e clusters Kubernetes para gerenciar desempenhos máximos</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="wpo-solutions-item">
                                        <div className="wpo-solutions-icon">
                                            <div className="icon">
                                                <img src={prIcon2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wpo-solutions-text">
                                            <h2><Link onClick={ClickHandler} to="/#">Multi cluster</Link></h2>
                                            <p>Facilite operações colaborativas e ágeis com fácil compartilhamento de contêineres entre vários nós e clusters </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="wpo-solutions-item">
                                        <div className="wpo-solutions-icon">
                                            <div className="icon">
                                                <img src={prIcon3} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wpo-solutions-text">
                                            <h2><Link onClick={ClickHandler} to="/#">Kubernetes native</Link></h2>
                                            <p>Use ferramentas e APIs que você conhece para ter uma experiência consistente </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <p>Orquestre aplicativos conteinerizados em vários ambientes. Implante, dimensione e atualize aplicativos em contêineres com facilidade com clusters Kubernetes. Suporte ponta a ponta de administradores certificados do Kubernetes. </p>
                            <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem Ut enim ad minima veniam, quis nostrum exercitationem .</p>
                        </div>
                        <br></br>
                   
                     
              <Support fnClass={"section-padding"} />
              
                    
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Services;