import React, { Fragment } from 'react';



const BlogPage =() => {
    return(
        <Fragment>

        </Fragment>
    )
};
export default BlogPage;

