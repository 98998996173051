import React from 'react'
import { Link } from 'react-router-dom'

import prjImg1 from '../../../../images/project-single/prom-img-1.jpg'
import prjImg2 from '../../../../images/project-single/prom-img-2.jpg'
import prjImg3 from '../../../../images/project-single/prom-img-3.jpg'

import prIcon2 from '../../../../images/icon/bar-graph.svg'
import prIcon3 from '../../../../images/icon/clipboard.svg'
import prIcon1 from '../../../../images/icon/document.svg'



const Services = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


    return(
        <section className="wpo-project-single-section section-padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <div className="wpo-project-single-wrap">
                        <div className="wpo-project-single-content">
                            <img src={prjImg1} alt=""/>
                            <div className="wpo-project-single-content-des">
                                <div className="wpo-project-single-content-des-wrap">
                                    <div className="wpo-project-single-content-des-left">
                                        <h2>Transformando Dados em Insight com prometheus</h2>
                                        <p>Em um mundo cada vez mais dependente de tecnologia, o monitoramento eficaz de sistemas e aplicativos é crucial para o sucesso das empresas. É aí que o Prometheus entra em cena como uma ferramenta indispensável para coleta, armazenamento e análise de métricas. Vamos explorar como essa poderosa ferramenta de monitoramento pode transformar seus dados em insights valiosos.</p>
                                        <p>Com o Prometheus, você não apenas obtém uma visão clara do estado da sua infraestrutura e aplicativos em tempo real, mas também pode tomar decisões informadas e rápidas com base em dados precisos. Ele ajuda a detectar problemas antes que afetem os usuários finais, otimizando a experiência do cliente e aumentando a eficiência operacional.

Se você deseja aproveitar ao máximo seu ambiente de tecnologia e garantir que sua infraestrutura funcione de forma confiável e eficaz, o Prometheus é a ferramenta de métricas que você estava esperando. Não deixe a complexidade da monitorização prejudicar seu sucesso. Adote o Prometheus hoje e transforme seus dados em insight. Sua empresa merece o melhor, e o Prometheus pode fornecer exatamente isso.</p>
                                    </div>
                                    <div className="wpo-project-single-content-des-right">
                                        <ul>
                                            <li>Name:<span>Prometheus</span></li>
                                            <li>License :<span>OpenSource</span></li>
                                            <li>Source :<span><Link href="https://github.com/prometheus/prometheus">Github</Link></span></li>
                                            <li>Doc: <span> <Link href="https://prometheus.io/docs/introduction/overview/">Oficial</Link></span></li>
                                            <li>Tag :<span>observability, metrics</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wpo-project-single-sub-img">
                                    <ul>
                                        <li><img src={prjImg2} alt=""/></li>
                                        <li><img src={prjImg3} alt=""/></li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="wpo-solutions-section">
                            <h2>Benefícios</h2>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="wpo-solutions-item">
                                        <div className="wpo-solutions-icon">
                                            <div className="icon">
                                                <img src={prIcon1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wpo-solutions-text">
                                            <h2><Link onClick={ClickHandler} to="/#">Escalabilidade</Link></h2>
                                            <p>À medida que sua organização cresce, a plataforma de Prometheus Metrics pode escalar para atender às novas demandas de monitoramento, garantindo que você mantenha o controle mesmo em ambientes complexos. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="wpo-solutions-item">
                                        <div className="wpo-solutions-icon">
                                            <div className="icon">
                                                <img src={prIcon2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wpo-solutions-text">
                                            <h2><Link onClick={ClickHandler} to="/#">Alertas Personalizados</Link></h2>
                                            <p>Você pode configurar alertas personalizados para ser notificado instantaneamente sobre problemas críticos ou anomalias em seu ambiente, permitindo ação rápida para evitar interrupções. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="wpo-solutions-item">
                                        <div className="wpo-solutions-icon">
                                            <div className="icon">
                                                <img src={prIcon3} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wpo-solutions-text">
                                            <h2><Link onClick={ClickHandler} to="/#">Fácil integração</Link></h2>
                                            <p>projetadas para integração com uma variedade de tecnologias, como contêineres, orquestradores (ex. Kubernetes) e serviços de nuvem, garantindo compatibilidade com sua infraestrutura existente.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <p>Contratar uma plataforma de serviços de Prometheus Metrics oferece diversos benefícios para organizações que desejam um sistema de monitoramento robusto e eficiente. Aqui estão alguns dos principais benefícios: </p>
                            <p>Investir em uma plataforma de serviços de Prometheus Metrics oferece uma solução de monitoramento abrangente, flexível e eficaz que melhora a confiabilidade, a estabilidade e o desempenho dos sistemas e aplicativos da sua organização.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Services;