import React from 'react';


const Numbers = (props) => {

    const funfact = [
        {
            title: '71%',
            subTitle: 'Previsibilidade de custos',
        },
        {
            title: '68%',
            subTitle: 'Otimizar workloads',
        },
        {
            title: '46%',
            subTitle: 'Visibilidade dos KPIs',
        },
        {
            title: '58%',
            subTitle: 'Equipe dedicada',
        },


    ]

    return (
        <section className={`wpo-fun-fact-section  ${props.fnClass}`}>
                                <div className="row">
                        <div className="col-12">
                            <div className="wpo-section-title">
                                <span>O desafio</span>
                                <h2>DA GESTÃO DO FINOPS NAS EMPRESAS</h2>
                            </div>
                        </div>
                    </div>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12" >
                        <div className="wpo-fun-fact-grids clearfix">
                            {funfact.map((funfact, fitem) => (
                                <div className="grid" key={fitem}>
                                    <div className="info">
                                        <h3>{funfact.title}</h3>
                                        <p>{funfact.subTitle}</p>
                                        </div>
                                   
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Numbers;