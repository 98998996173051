import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },

}));

const Benefits = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className="wpo-benefits-section">
            <h2>Benefícios</h2>
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="wpo-benefits-item">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography className={classes.heading}>Por que devo contratar um serviço DevOps da CodeView?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                Possuimos os melhores profissionais para apoiar seu time de desenvolvimento em:
                                <br/>
                                <b>Entregas mais rápidas: </b>Com automação e integração contínua, você pode lançar software mais rapidamente.<br/>
                                <b>Melhor qualidade: </b>Reduz falhas e erros, garantindo um software mais confiável.<br/>
                                <b>Eficiência operacional: </b>Automatize tarefas, economizando tempo e recursos.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography className={classes.heading}>Como o DevOps como serviço melhora a colaboração?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                O DevOps promove a colaboração entre equipes de desenvolvimento e operações, resultando em resolução mais rápida de problemas e entrega eficaz de software.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography className={classes.heading}>Como o DevOps como serviço impacta a satisfação do cliente?</Typography>
                
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                Entregas mais rápidas e software confiável resultam em uma melhor experiência do cliente e maior satisfação.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Typography className={classes.heading}>Por que o DevOps é essencial nos negócios atuais?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                O DevOps é fundamental para impulsionar a agilidade, eficiência e competitividade nos negócios modernos, tornando-se um investimento valioso.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Benefits;