import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import NotFound from '../pages/404/index';
import About from '../pages/About';
import Blog from '../pages/Blog';
import ConsultingFinOpsPage from '../pages/Consulting/finops';
import ConsultingMigrationPage from '../pages/Consulting/migration';
import ConsultingSecPage from '../pages/Consulting/security';
import ConsultingTeamPage from '../pages/Consulting/teams';
import ServicesDevOpsPage from '../pages/Services/devops';
import ServicesKubernetesPage from '../pages/Services/kubernetes';
import ServiceObserverPage from '../pages/Services/observability';
import ServicesSrePage from '../pages/Services/sre';

import PlatformElasticPage from '../pages/Platforms/elastic';
import PlatformKubernetesPage from '../pages/Platforms/kubernetes';
import PlatformPrometheusPage from '../pages/Platforms/prometheus';

import Contact from '../pages/Contact';
import Home from '../pages/Home';
      
const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/sobre' component={About} />
            <Route path='/servicos-ti/devops' component={ServicesDevOpsPage} />
            <Route path='/servicos-ti/observabilidade' component={ServiceObserverPage} />
            <Route path='/servicos-ti/kubernetes' component={ServicesKubernetesPage} />
            <Route path='/servicos-ti/engenharia-de-confiabilidade' component={ServicesSrePage} />
            <Route path='/consultoria-ti/#' />
            <Route path='/consultoria-ti/finops' component={ConsultingFinOpsPage} />
            <Route path='/consultoria-ti/migracao' component={ConsultingMigrationPage} />
            <Route path='/consultoria-ti/alocacao-de-profissionais' component={ConsultingTeamPage} />
            <Route path='/consultoria-ti/seguranca' component={ConsultingSecPage} />
            <Route path='/plataformas/prometheus-stack-como-servico' component={PlatformPrometheusPage} />
            <Route path='/plataformas/elastic-stack-servico' component={PlatformElasticPage} />
            <Route path='/plataformas/kubernetes-servico-gerenciado' component={PlatformKubernetesPage} />
            <Route path='/blog' component={Blog} />
            <Route path='/contato' component={Contact} />
            <Route component={NotFound} />
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
