import React from "react";
import { Link } from "react-router-dom";

import prdimg3 from "../../../images/cdn/home/confiability.png";
import prdimg1 from "../../../images/cdn/home/modernization.png";
import prdimg2 from "../../../images/cdn/home/platform.png";

const Features = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const featres = [
    {
      fIcon: prdimg1,
      title: "Modernização",
      des: "Modernize sua infraestrutura com Engenharia de Plataforma: Automatize processos, otimize recursos e acelere a entrega de software na nuvem.",
      link: "/servicos-ti/kubernetes",
    },
    {
      fIcon: prdimg2,
      title: "Plataforma",
      des: "Potencialize seu desenvolvimento com plataformas de autoatendimento: Simplifique processos, ganhe agilidade e libere todo o seu potencial criativo.",
      link: "/servicos-ti/devops",
    },
    {
      fIcon: prdimg3,
      title: "Confiabilidade",
      des: "Garanta a confiabilidade dos seus sistemas: Priorizamos a redundância, monitoramento constante e atualizações automáticas para tranquilidade total.",
      link: "/servicos-ti/observabilidade",
    },
  ];

  return (
    <section
      className={`wpo-features-section section-padding  ${props.featuresClass}`}
    >
      <div className="container">
        <div className="row">
          {featres.map((featres, fitem) => (
            <div className="col-lg-4 col-md-6 col-12" key={fitem}>
              <div className="wpo-features-item">
                <div className="wpo-features-icon">
                  <div className="icon">
                    <img src={featres.fIcon} alt="" />
                  </div>
                </div>
                <div className="wpo-features-text">
                  <h2>
                    <Link onClick={ClickHandler} to={featres.link}>
                      {featres.title}
                    </Link>
                  </h2>
                  <p>{featres.des}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
