import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },

}));

const Benefits = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className="wpo-benefits-section">
            <h2>Benefícios</h2>
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="wpo-benefits-item">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography className={classes.heading}>O que é observability as a service (observabilidade como um serviço) e por que devo considerá-lo?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                A observabilidade como serviço (Observability as a Service) é uma solução que fornece visibilidade completa e em tempo real sobre o desempenho e o funcionamento de sistemas e aplicativos. Contratar essa solução é benéfico, pois permite que você se concentre no desenvolvimento e na entrega de produtos enquanto especialistas gerenciam a observação e a coleta de dados operacionais.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography className={classes.heading}>Como a observabilidade como serviço melhora a detecção de problemas?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                A observabilidade como serviço aprimora a detecção de problemas por meio da análise contínua de métricas, logs e rastreamentos. Ele detecta anomalias e tendências negativas em tempo real, permitindo a resolução proativa de problemas antes que eles afetem os usuários.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography className={classes.heading}>Quais são os benefícios para a escalabilidade de negócios?</Typography>
                
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                A escalabilidade de negócios é aprimorada com a observabilidade como serviço, pois ela permite dimensionar recursos de acordo com as necessidades. Isso economiza custos, evita interrupções e garante que o desempenho permaneça otimizado, mesmo com o aumento da carga de trabalho.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Typography className={classes.heading}> Como a observabilidade como serviço ajuda na otimização de recursos?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                A observabilidade como serviço ajuda na otimização de recursos, identificando ineficiências de desempenho e permitindo a alocação precisa de recursos. Isso evita o desperdício de recursos computacionais e financeiros.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Benefits;