import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";


class Services2 extends Component {
    render() {
        var settings = {
            dots: true,
            arrows: true,
            speed: 3000,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const ClickHandler = () => {
            window.scrollTo(10, 0);
        }



        const service = [
            {
                sIcon: 'fi fi-sr-dollar',
                title: 'Análise de custos',
                des: 'Realizamos uma análise abrangente de custo de nuvem para entender de onde vêm seus gastos com visibilidade seus times.',
                link: '/#',
            },
            {
                sIcon: 'fi fi-rr-users-alt',
                title: 'Otimização de recursos',
                des: 'Trabalhamos em colaboração com sua equipe para identificar recursos que não estão sendo usados e ou estão ociosos.',
                link: '/#',
            },            
            {
                sIcon: 'fi fi-rs-database',
                title: 'Reserva de recursos',
                des: 'Após analisar o padrão de uso da sua nuvem, nós recomendamos a compra de instâncias reservadas para uso previsível.',
                link: '/#',
            },
            {
                sIcon: 'fi fi-rr-dice-d6',
                title: 'Cultura da responsabilidade',
                des: 'Aumente a visibilidade nos gastos e oportunidades de melhoria nos custos com KPIs e métricas claras.',
                link: '/#',
            },
            {
                sIcon: 'fi fi-rs-bell-ring',
                title: 'Monitoramento e alertas',
                des: 'Receba notificações quando o uso da nuvem excede os limites definidos. Evite supresas na fatura.',
                link: '/#',
            },



        ]
        return (
            <section className={`wpo-service-section section-padding ${this.props.srvClass}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="wpo-section-title">
                                <span>Infraestrutura saudável com</span>
                                <h2>Cloud Computing FinOps </h2>
                            </div>
                        </div>
                    </div>
                    <div className="wpo-service-items wpo-service-slider owl-carousel">
                        <Slider {...settings}>
                            {service.map((service, sitem) => (
                                <div className="col-lg-4 col-md-6 col-12" key={sitem}>
                                    <div className="wpo-service-item">
                                        <div className="wpo-service-icon">
                                            <div className="icon">
                                                <i className={service.sIcon}></i>
                                            </div>
                                        </div>
                                        <div className="wpo-service-text">
                                            <h2><Link onClick={ClickHandler} to={service.link}>{service.title}</Link></h2>
                                            <p>{service.des}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        
                    </div>
                </div>
            </section>
        );
    }
}

export default Services2;



