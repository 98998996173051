import React from 'react';
import { Link } from 'react-router-dom';


const ServiceSingleSidebar = (props) => {
    return(
        <div className="col-lg-4 col-md-8">
            <div className="wpo-single-sidebar">
                <div className="wpo-service-widget widget">
                        <h2>Nossos serviços</h2>
                        <ul>
                            <li><Link to="#">Implantação e Gestão</Link></li>
                            <li><Link to="#">Otimização e performance</Link></li>
                            <li><Link to="#">Gestão de custos</Link></li>
                            <li><Link to="#">Capacity Planning</Link></li>
                            <li><Link to="#">Observabilidade</Link></li>
                            <li><Link to="#">Segurança e conformidade</Link></li>
                        </ul>
                </div>

                <div className="wpo-contact-widget widget">
                        <h2>Conheça nosso <br/> serviços de administração!</h2>
                        <p>Criamos uma estratégia Kubernetes sem interrupção para ficar à frente de seus concorrentes. Garantimos que você faça o melhor uso, desde o gerenciamento sem esforço de aplicativos em contêineres e automação de fluxos de trabalho em nível de homologação a produção visando a redução de custos operacionais e maior produtividade e disponibilidade.</p>
                        <Link to="/contato">Fale conosco</Link>
                </div>
            </div>
        </div>
    )
}

export default ServiceSingleSidebar;