import React from 'react'
import { Link } from 'react-router-dom'

import srvimg2 from '../../../../images/icon/briefcase.svg'
import srvimg3 from '../../../../images/icon/chart.svg'
import srvimg1 from '../../../../images/icon/clipboard.svg'


const Solutions = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


     const service = [
         {
            sIcon:srvimg1,
            title:'Instalação',
            des:'Oferecemos serviços especializados em Kubernetes, focados em economia e infraestrutura como código (IAC).',      
            link:'/#',
         },
         {
            sIcon:srvimg2,
            title:'Administração',
            des:'Nosso modelo de administração prioriza planejamento de capacidade e monitoramento 24/7 avançado.',      
            link:'/#',
         },
         {
            sIcon:srvimg3,
            title:'Arquitetura e Design',
            des:'Obtenha flexibilidade infinita, escalabilidade virtual e implantações mais rápidas para se adaptar às mudanças constantes.',      
            link:'/#',
         },
         
     ]


    return(
        <div className="wpo-solutions-section">
            <h2>Soluções</h2>
            <div className="row">
                {service.map((service, sitem) => (
                    <div className="col-lg-4 col-md-6 col-12" key={sitem}>
                        <div className="wpo-solutions-item">
                            <div className="wpo-solutions-icon">
                                <div className="icon">
                                    <img src={service.sIcon} alt=""/>
                                </div>
                            </div>
                            <div className="wpo-solutions-text">
                                <h2><Link onClick={ClickHandler} to={service.link}>{service.title}</Link></h2>
                                <p>{service.des}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        
    )
}

export default Solutions;