import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../components/Navbar';
import Support from '../../components/Support';
import Footer from '../../components/footer';
import Hero from '../../components/hero';
import Scrollbar from '../../components/scrollbar';
import Services from './components/Content';
import Features from './components/Features';
import Texts from './components/Texts';

const HomePage =() => {
    return(
        <Fragment>
        <Helmet>
            <title>CodeView Consultoria</title>
            <meta name="description" content="Descubra a Engenharia de Plataforma: Automatize, otimize e transforme sua infraestrutura para impulsionar a inovação e o crescimento." />
            <meta name="keywords" content="sre, devops, observability, kubernetes, automação, pipelines, CI/CD" />
            <meta name="author" content="" />
            <meta property="og:title" content="O poder da engenharia de plataforma" />
            <meta property="og:description" content="Automatize processos e maximize a eficiência operacional." />
        </Helmet>            
            <Navbar/>
            <Hero/>
            <Features/>
            <Texts/>
            <Services srvClass={'wpo-service-section-s2'}/>
            <br></br>
            <br></br>
            <Support/>
            <br></br>
            <br></br>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;