import React from "react";
import { Link } from "react-router-dom";

import cnt3 from "../../../images/icon/app.svg";
import cnt1 from "../../../images/icon/home.svg";
import cnt2 from "../../../images/icon/mail-2.svg";

const ContactPage = () => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              <div className="row">
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <img src={cnt1} alt="" />
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Endereço</h2>
                      <p>
                        R. Barão de Jundiaí, 523 - Lapa, São Paulo - SP,
                        05073-010, Brasil
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <img src={cnt2} alt="" />
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Nossos emails</h2>
                      <p>devops@codeview.com.br</p>
                      <p>contato@codeview.com.br</p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <img src={cnt3} alt="" />
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Telefones</h2>
                      <p>+55 11 94050-1851 </p>
                      <p>+0 </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpo-contact-title">
              <h2>Fale com um especialista?</h2>
              <Link
                className="theme-btn"
                to={{ pathname: "https://b24-7e9pvh.bitrix24.site/" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Clique aqui
              </Link>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
          <iframe title="Address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1829.109107126815!2d-46.70765070370376!3d-23.52465267429235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef862c27e826d%3A0x3904e53cf4d07da0!2sR.%20Bar%C3%A3o%20de%20Jundia%C3%AD%20-%20Lapa%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005073-010%2C%20Brasil!5e0!3m2!1spt-BR!2sus!4v1707145073842!5m2!1spt-BR!2sus"></iframe>
        </div>
      </section>
    </section>
  );
};

export default ContactPage;
