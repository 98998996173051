import React, { Fragment } from "react";
import { Helmet } from 'react-helmet';
import Navbar from "../../components/Navbar";
import Support from "../../components/Support";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import About from "./components/Content";
import PageTitle from "./components/PageHeader";

const AboutPage = () => {
  return (
    <Fragment>
      <Helmet>
          <title>Sobre nós</title>
          <meta name="description" content="Nossa experiência abrange diversos setores, desenvolvendo know-how para atender a uma ampla gama de projetos. Saiba mais." />
          <meta name="keywords" content="sre, devops, observability, kubernetes, automação, pipelines, CI/CD" />
          <meta name="author" content="" />
          <meta property="og:title" content="O poder da engenharia de plataforma" />
          <meta property="og:description" content="Automatize processos e maximize a eficiência operacional." />
      </Helmet>       
      <Navbar />
      <PageTitle
        pageTitle={"Somos a CodeView Consultoria"}
        pagesub={"Sobre nós"}
      />
      <About abClass={"wpo-about-section-s2"} />
      <Support />
      <br></br>
      <br></br>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
