import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AllRoute from './router';
import './sass/style.scss';
import ReactGA from 'react-ga4';

const App = () => { 
  ReactGA.initialize('G-SWTFLK8F6R');
  
  useEffect(() => {
    // Load Bitrix24 widget script dynamically
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.bitrix24.com.br/b28536381/crm/site_button/loader_2_zpwpsr.js?' + (Date.now() / 60000 | 0);
    document.body.appendChild(script);

    return () => {
      // Clean up the script when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="App" id='scrool'>
      <AllRoute/>
      <ToastContainer/>
    </div>
  );
}

export default App;
