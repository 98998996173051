import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const ServiceSingleSidebar = (props) => {
    ReactGA.event({
        category: 'Contact Links',
        action: 'Click',
        label: props,
    });

    return(
        <div className="col-lg-4 col-md-8">
            <div className="wpo-single-sidebar">
                <div className="wpo-service-widget widget">
                        <h2>Nossos serviços</h2>
                        <ul>
                            <li><Link to="#">Coleta de Logs</Link></li>
                            <li><Link to="#">Serviços de Métricas</Link></li>
                            <li><Link to="#">Rastreamento distribuido</Link></li>
                            <li><Link to="#">Alertas e Notificações</Link></li>
                            <li><Link to="#">Gestão de Incidentes</Link></li>
                            <li><Link to="#">Monitoramento Cloud</Link></li>
                        </ul>
                </div>

                <div className="wpo-contact-widget widget">
                        <h2>Como nós ajudamos <br/> você cliente!</h2>
                        <p>A CodeView Consultoria capacita seus clientes a alcançar uma observabilidade completa e telemetria abrangente em suas operações de cloud e Kubernetes. Nossas soluções especializadas garantem monitoramento proativo, análise de dados em tempo real e insights valiosos para otimizar o desempenho e a confiabilidade de seus sistemas. </p>
                        <Link to="/contato">Fale conosco</Link>
                </div>
            </div>
        </div>
    )
}

export default ServiceSingleSidebar;