import React from 'react'
import { Link } from 'react-router-dom'

import elasticStack from '../../../../images/icon/elastic-stack.svg'
import grafana from '../../../../images/icon/grafana.svg'
import jorney from '../../../../images/icon/journey-map.svg'
import omnichannel from '../../../../images/icon/omnichannel.svg'
import user from '../../../../images/icon/process-optimization.svg'
import prometheus from '../../../../images/icon/prometheus.svg'


const Solutions = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


     const service = [
         
{
    sIcon: prometheus,
    title: 'Prometheus',
    des: 'Monitore seus sistemas e aplicativos de forma inteligente com Prometheus, uma solução robusta e escalável.',
    link: '/#',
    },
    {
    sIcon: elasticStack,
    title: 'Elastic Stack',
    des: 'Obtenha insights valiosos de seus dados com o Elastic Stack, uma plataforma de busca e análise de alto desempenho.',
    link: '/#',
    },
    {
    sIcon: grafana,
    title: 'Grafana Stack',
    des: 'Visualize seus dados de forma eficaz e intuitiva com o Grafana Stack, uma plataforma de painéis e gráficos poderosa.',
    link: '/#',
    },
    {
    sIcon: jorney,
    title: 'Customer Journey',
    des: 'Entenda a jornada do seu cliente e melhore sua experiência com nossas soluções personalizadas.',
    link: '/#',
    },
    {
    sIcon: omnichannel,
    title: 'Confiabilidade',
    des: 'Garanta a confiabilidade de seus sistemas e aplicativos com nossos serviços especializados.',
    link: '/#',
    },
    {
    sIcon: user,
    title: 'User Experience',
    des: 'Melhore a experiência do usuário com análises detalhadas e orientadas por dados.',
    link: '/#',
    },
         
     ]


    return(
        <div className="wpo-solutions-section">
            <h2>Tech Stack</h2>
            <div className="row">
                {service.map((service, sitem) => (
                    <div className="col-lg-4 col-md-6 col-12" key={sitem}>
                        <div className="wpo-solutions-item">
                            <div className="wpo-solutions-icon">
                                <div className="icon">
                                    <img src={service.sIcon} alt=""/>
                                </div>
                            </div>
                            <div className="wpo-solutions-text">
                                <h2><Link onClick={ClickHandler} to={service.link}>{service.title}</Link></h2>
                                <p>{service.des}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        
    )
}

export default Solutions;