import React from 'react'
import { Link } from 'react-router-dom'
import insimg5 from '../../../../images/cdn/tools/ansible-logo-gray.jpg'
import insimg4 from '../../../../images/cdn/tools/argo-logo-gray.jpg'
import insimg3 from '../../../../images/cdn/tools/elastic-logo-gray.jpg'
import insimg2 from '../../../../images/cdn/tools/grafana-logo-gray.jpg'
import insimg1 from '../../../../images/cdn/tools/kube-logo-gray.jpg'
import insimg6 from '../../../../images/cdn/tools/prom-logo-gray.jpg'


const ServiceSingleSidebar = (props) => {
    return(
        <div className="col-lg-4 col-md-8">
            <div className="wpo-single-sidebar">
                <div className="wpo-service-widget widget">
                        <h2>Nossos serviços</h2>
                        <ul>
                            <li><Link to="#">Pipelines CI/CD</Link></li>
                            <li><Link to="#">Seleção conjunto de ferramentas</Link></li>
                            <li><Link to="#">Infraestrutura como código (IaC)</Link></li>
                            <li><Link to="#">Containers e Orquestração</Link></li>
                            <li><Link to="#">Monitoramento e Observabilidade</Link></li>
                            <li><Link to="#">Análise e Integrações de Segurança</Link></li>
                        </ul>
                </div>

                <div className="wpo-instagram-widget widget">
                        <h2>Ferramentas em destaque</h2>

                        <ul>
                            <li><Link to={{pathname:`https://kubernetes.io/pt-br/`}} target="_blank"><img src={insimg1} alt="Kubernetes"/></Link></li>
                            <li><Link to={{pathname:`https://grafana.com/oss/`}} target="_blank"><img src={insimg2} alt="Grafana OSS"/></Link></li>
                            <li><Link to={{pathname:`https://www.elastic.co/elastic-stack`}} target="_blank"><img src={insimg3} alt="ElasticStack"/></Link></li>
                            <li><Link to={{pathname:`https://argoproj.github.io/cd/`}} target="_blank"><img src={insimg4} alt="ArgoCD"/></Link></li>
                            <li><Link to={{pathname:`https://www.ansible.com/`}} target="_blank"><img src={insimg5} alt="Ansible"/></Link></li>
                            <li><Link to={{pathname:`https://prometheus.io/`}} target="_blank"><img src={insimg6} alt="Prometheus"/></Link></li>
                        </ul>
                </div>

                <div className="wpo-contact-widget widget">
                        <h2>Transforme seu Negócio<br/> com serviços de DevOps!</h2>
                        <p>Potencialize sua empresa com expertise em tecnologia. Descubra como nossos serviços podem impulsionar seu sucesso. Entre em contato agora! </p>
                        <Link to={{ pathname: "https://calendly.com/codeview"}} target="_blank" rel="noopener noreferrer">Agende uma conversa</Link>
                </div>
            </div>
        </div>
    )
}

export default ServiceSingleSidebar;