import React from 'react';
import Error from '../../components/404/index';

const NotFound = () => {
  return (
    <Error />
  );
};

export default NotFound;
