import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import FunFact from '../../../components/FunFact';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/footer';
import Scrollbar from '../../../components/scrollbar';
import Services from "./component/Content";
import PageTitle from './component/PageHeader';

const ServicesDevOpsPage =() => {
    return(
        <Fragment>
            <Helmet>
                <title>DevOps como um serviço</title>
                <meta name="description" content="Transforme sua equipe com DevOps como serviço. Automatize, integre e acelere o desenvolvimento de software. Descubra mais sobre nossos serviços agora!" />
                <meta name="keywords" content="sre, devops, observability, kubernetes, ansible, configuration manager, aws, gce, oci, azure" />
                <meta name="author" content="" />
                <meta property="og:title" content="O poder da engenharia de plataforma" />
                <meta property="og:description" content="Automatize processos e maximize a eficiência operacional." />
            </Helmet>             
            <Navbar/>
            <PageTitle pageTitle={'DevOps como Serviço'} pagesub={'services-it/devops'}/> 
            <Services srvClass={'wpo-service-section-s3'}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ServicesDevOpsPage;
