import React from "react";
import spimg1 from "../../../images/ab-shape-1.png";
import spimg2 from "../../../images/ab-shape-2.png";
import abimg from "../../../images/cdn/about/about-content.jpg";

const About = (props) => {

  return (
    
    <section className={`wpo-about-section ${props.abClass}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-wrap">
              <div className="wpo-about-img">
                <img src={abimg} alt="" />
                <div className="wpo-ab-shape-1">
                  <img src={spimg1} alt="" />
                </div>
                <div className="wpo-ab-shape-2">
                  <img src={spimg2} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-text">
              <div className="wpo-about-icon-content">
                <h2>
                  Inovação <span>e determinação</span>{" "}
                </h2>
                <p>
                  Crescimento e eficiência em operações de TI é o nosso forte,
                  nosso compromisso vai além de contratos atuamos como parte da
                  solução.
                </p>
                <div className="wpo-service-single-content-des">
                  <h3>
                  Agilidade
                  </h3>
                  <p>
                  Sabemos o quanto é importante acertar o timing de um projeto de software, por isso trabalhamos com metodologias ágeis e equipes multidisciplinares.
                  </p>
                  <h3>
                  Experiência
                  </h3>
                  <p>
                  Ao longo da nossa história trabalhamos com diversos segmentos e desenvolvemos o know-how necessário para atuar nos mais diversificados projetos.
                  </p>
                  <h3>
                  Qualidade
                  </h3>
                  <p>
                  Equipe qualificada com 100% de dedicação ao negócio, envolvendo as pessoas certas e as melhores tecnologias disponíveis.
                  </p>     
                  <h3>
                  Cuidado e respeito
                  </h3>
                  <p>
                  Para nós é importante ter um bom equilíbrio entre vida e trabalho e cuidar uns dos outros. Investimos muito em iniciativas de bem-estar e em passar tempo juntos.
                  </p>               
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
    
  );
};

export default About;
