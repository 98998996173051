import React from 'react'
import ssimg from '../../../../images/service-single/obs-img-1.jpg'
import ssimg2 from '../../../../images/service-single/obs-img-2.jpg'
import ssimg3 from '../../../../images/service-single/obs-img-3.jpg'
import Benefits from './benefits'
import ServiceSingleSidebar from './sidebar'
import Solutions from './solution'


const Services = (props) => {
    return(
        <section className="wpo-service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="wpo-service-single-wrap">
                            <div className="wpo-service-single-content">
                                <img src={ssimg} alt=""/>
                                <div className="wpo-service-single-content-des">
                                    <h2>Personalize o monitoramento e observabilidade do seu negócio.</h2>
                                    <p>Em um mundo digital, a observabilidade se tornou fundamental para o sucesso de qualquer empresa. Monitorar seu ambiente de negócios em tempo real, compreender o comportamento de aplicativos e sistemas, e identificar problemas antes que afetem seus clientes é essencial.</p>
                                    <p>Na CodeView Consultoria, oferecemos soluções sob medida para atender às necessidades exclusivas do seu negócio. Com anos de experiência em consultoria de tecnologia, nossa equipe especializada em observabilidade e monitoramento ajuda você a implementar as melhores práticas e ferramentas para sua infraestrutura.</p>
                                    <div className="wpo-service-single-sub-img">
                                        <ul>
                                            <li><img src={ssimg2} alt=""/></li>
                                            <li><img src={ssimg3} alt=""/></li>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                            <Solutions/>
                            <Benefits/>
                        </div>
                    </div>
                    <ServiceSingleSidebar/>
                    
                </div>
            </div>
        </section>
    )
}

export default Services;