import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const HeaderTopbar = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
        };

        handleResize(); 
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return !isMobile ? (
        <div className="topbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-md-7 col-sm-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="fi ti-location-pin"></i>Rua Barão de Jundiaí, 523 - Lapa, São Paulo - SP, 05073-010</li>
                                <li><i className="fi flaticon-email"></i> contato@codeview.com.br</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-md-5 col-sm-12 col-12">
                        <div className="contact-info">
                            <ul>
                                <li>Redes sociais</li>
                                <li><Link to={{pathname:`https://www.facebook.com/codeviewconsultoria/`}} target="_blank"><i className="ti-facebook"></i></Link></li>
                                <li><Link to={{pathname:`https://twitter.com/codeviewdevops`}} target="_blank"><i className="ti-twitter-alt"></i></Link></li>
                                <li><Link to={{pathname:`https://www.instagram.com/codeviewconsultoria/`}} target="_blank"><i className="ti-instagram"></i></Link></li>
                                <li><Link to={{pathname:`https://www.linkedin.com/company/codeviewconsultoria/`}} target="_blank"><i className="ti-linkedin"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}

export default HeaderTopbar;
