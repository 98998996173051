import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import ContactForm from './component/Contactpage';
import PageTitle from './component/PageHeader';

const ContactPage =() => {
    return(
        <Fragment>
            <Helmet>
                <title>Fale conosco</title>
                <meta name="description" content="Entre em contato conosco para soluções personalizadas. Estamos prontos para ajudar sua empresa a alcançar seus objetivos. Saiba mais agora!" />
                <meta name="keywords" content="sre, devops, observability, kubernetes, automação, pipelines, CI/CD" />
                <meta name="author" content="" />
                <meta property="og:title" content="O poder da engenharia de plataforma" />
                <meta property="og:description" content="Automatize processos e maximize a eficiência operacional." />
            </Helmet>              
            <Navbar/>
            <PageTitle pageTitle={'Fale conosco'} pagesub={'Contact'}/> 
            <ContactForm/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ContactPage;

