import React from 'react'
import { Link } from 'react-router-dom'

import srvimg2 from '../../../../images/icon/briefcase.svg'
import srvimg3 from '../../../../images/icon/chart.svg'
import srvimg1 from '../../../../images/icon/clipboard.svg'


const Solutions = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


     const service = [
         {
            sIcon:srvimg1,
            title:'Avaliação Inicial (Assessment)',
            des:'É um processo que envolve várias etapas e desafios, mas também oferece recompensas substanciais em termos de qualidade, velocidade e eficiência.',      
            link:'/#',
         },
         {
            sIcon:srvimg2,
            title:'Estratégia Personalizada',
            des:'Com base nos insights da avaliação, trabalhamos em estreita colaboração com você para desenvolver uma estratégia de DevOps personalizada. ',      
            link:'/#',
         },
         {
            sIcon:srvimg3,
            title:'Monitoramento e Observabilidade',
            des:' A CodeView Consultoria ajuda a selecionar e implementar as ferramentas mais adequadas para automação, integração contínua, entrega contínua, monitoramento e muito mais.',      
            link:'/#',
         },
         {
            sIcon:srvimg3,
            title:'Automação de Processos',
            des:'Criação de pipelines de CI/CD (Integração Contínua/Entrega Contínua) para automatizar compilações, testes e implantações, reduzindo erros e acelerando a entrega de software.',      
            link:'/#',
         },
         {
            sIcon:srvimg3,
            title:'Integração Cultural (Cultura DevOps)',
            des:'Apoiamos times em promover uma cultura colaborativa, onde desenvolvedores e operadores trabalham em conjunto, compartilhando responsabilidades e objetivos comuns.',      
            link:'/#',
         },
         {
            sIcon:srvimg3,
            title:'Melhoria Contínua (Kaizen)',
            des:'Após a implementação inicial, trabalhamos com sua equipe para avaliar o progresso, identificar oportunidades de otimização e iterar constantemente sobre os processos e práticas.',      
            link:'/#',
         },                           
     ]


    return(
        <div className="wpo-solutions-section">
            <h2>Nossos serviços</h2>
            <div className="row">
                {service.map((service, sitem) => (
                    <div className="col-lg-4 col-md-6 col-12" key={sitem}>
                        <div className="wpo-solutions-item">
                            <div className="wpo-solutions-icon">
                                <div className="icon">
                                    <img src={service.sIcon} alt=""/>
                                </div>
                            </div>
                            <div className="wpo-solutions-text">
                                <h2><Link onClick={ClickHandler} to={service.link}>{service.title}</Link></h2>
                                <p>{service.des}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        
    )
}

export default Solutions;