import React, { Component } from "react";
import { Link } from 'react-router-dom';
import HeroImg from '../../images/cdn/home/home-hero_v1.jpg';

import VideoModal from '../ModalVideo';
class Hero extends Component {
    render() {

        return (
            <section className="wpo-hero-section-1">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-7 col-lg-7">
                            <div className="wpo-hero-section-text">
                                <div className="wpo-hero-title-top">
                                    <span>Consultoria especializada</span>
                                </div>
                                <div className="wpo-hero-title">
                                    <h2>Platform <span> engineering</span> </h2>
                                </div>
                                <div className="wpo-hero-subtitle">
                                    <p>Transforme sua infraestrutura em um alicerce robusto para o crescimento digital. Automatize, otimize e amplie sua capacidade de entrega de software.</p>
                                </div>
                                <div className="btns">
                                    <Link to={{ pathname: "https://b24-7e9pvh.bitrix24.site/"}} target="_blank" rel="noopener noreferrer" className="btn theme-btn">Fale com especialista</Link>
                                </div>
                                <div className="pop-up-video">
                                    <div className="video-holder">
                                        <VideoModal/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-img">
                        <img src={HeroImg} alt=""/>
                </div>
            </section>
        )
    }
}

export default Hero;