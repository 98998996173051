import React from 'react'
import { Link } from 'react-router-dom'
import erimg from '../../images/cdn/error/error-404.jpg'


const Error = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="error-404-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="content clearfix">
                            <div className="error">
                                <img src={erimg} alt=""/>
                            </div>
                            <div className="error-message">
                                <h3>Oops! Página não encontrada!</h3>
                                <p>Pedimos desculpas, mas não conseguimos localizar a página que você está tentando acessar. Isso pode ter ocorrido devido a um erro de digitação no endereço da web.</p>
                                <Link onClick={ClickHandler} to="/" className="theme-btn"><i className="fa fa-angle-double-left"></i> Voltar para home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Error;