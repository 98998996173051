import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../../components/Navbar';
import Support from '../../../components/Support';
import Footer from '../../../components/footer';
import Scrollbar from '../../../components/scrollbar';
import Services from "./component/Content";
import PageTitle from './component/PageHeader';

const ConsultingTeamPage =() => {
    return(
        <Fragment>
            <Helmet>
                <title>Alocação de profissionais</title>
                <meta name="description" content="Maximize a eficiência da sua equipe com profissionais altamente qualificados. Amplie a capacidade e potencialize os resultados." />
                <meta name="keywords" content="sre, devops, observability, kubernetes, automação, pipelines, CI/CD, finops, aws, gce, oci, azure" />
                <meta name="author" content="" />
                <meta property="og:title" content="O poder da engenharia de plataforma" />
                <meta property="og:description" content="Automatize processos e maximize a eficiência operacional." />
            </Helmet>            
            <Navbar/>
            <PageTitle pageTitle={'Amplie a capacidade do seus times'} pagesub={'consultoria-ti/alocacao-de-profissionais'}/> 
            <Services srvClass={'wpo-service-section-s3'}/>
            <br></br>
            <br></br>              
            <Support/>
            <br></br>
            <br></br>  
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ConsultingTeamPage;
