import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../../components/Navbar';
import Support from '../../../components/Support';
import Footer from '../../../components/footer';
import Scrollbar from '../../../components/scrollbar';
import Services from "./components/Content";
import Numbers from './components/Numbers';
import PageTitle from './components/PageHeader';

const ConsultingFinOpsPage =() => {
    return(
        <Fragment>
            <Helmet>
                <title>Cloud FinOps</title>
                <meta name="description" content="Otimize seus gastos na nuvem com estratégias eficientes de FinOps. Economize e maximize seus recursos na nuvem. Descubra como agora" />
                <meta name="keywords" content="sre, devops, observability, kubernetes, automação, pipelines, CI/CD" />
                <meta name="author" content="" />
                <meta property="og:title" content="O poder da engenharia de plataforma" />
                <meta property="og:description" content="Automatize processos e maximize a eficiência operacional." />
            </Helmet>             
            <Navbar/>
            <PageTitle pageTitle={'Reduza custos com FinOps'} pagesub={'consultoria-ti/finops'}/> 
            <Services srvClass={'wpo-service-section-s3'}/>
            <Numbers fnClass={'section-padding'} />
            <Support fnClass={'section-padding'}/>
            <br></br>
            <br></br>            
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ConsultingFinOpsPage;
