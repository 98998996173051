import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },

}));

const Benefits = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className="wpo-benefits-section">
            <h2>Benefícios</h2>
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="wpo-benefits-item">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography className={classes.heading}> Quais são os benefícios de utilizar Kubernetes?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                Kubernetes oferece diversos benefícios, como escalabilidade automática, alta disponibilidade, gerenciamento de recursos eficiente, atualizações de aplicativos sem tempo de inatividade e portabilidade de aplicativos entre ambientes.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography className={classes.heading}> Como a administração de Kubernetes pode melhorar a segurança dos meus aplicativos?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                A administração adequada de Kubernetes inclui a implementação de práticas recomendadas de segurança, como autenticação forte, controle de acesso baseado em função (RBAC), e o uso de Network Policies para restringir a comunicação entre pods. Isso ajuda a fortalecer a segurança dos aplicativos em execução.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography className={classes.heading}>O que devo considerar ao escolher um serviço de administração de Kubernetes?</Typography>
                
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                Ao escolher um serviço de administração de Kubernetes, considere a experiência da equipe, as práticas de segurança implementadas, a eficiência operacional, a escalabilidade do serviço e o suporte técnico oferecido. Certifique-se de que o serviço atenda às necessidades específicas da sua empresa.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Typography className={classes.heading}>Como a CodeView Consultoria lida com a escalabilidade de clusters Kubernetes?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                A CodeView Consultoria lida com a escalabilidade de clusters Kubernetes de maneira estratégica, usando recursos como Horizontal Pod Autoscaling (HPA) para ajustar automaticamente a capacidade de acordo com a demanda. Isso garante que seus aplicativos estejam sempre prontos para atender às necessidades em constante mudança.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Benefits;