import React, { Fragment } from 'react';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/footer';
import PageTitle from '../../../components/pagetitle';
import Scrollbar from '../../../components/scrollbar';
import Services from "./component/Content";

const ServicesSrePage =() => {
    return(
        <Fragment>
            <Helmet>
                <title>SRE - Confiabilidade de sites</title>
                <meta name="description" content="Otimize seu ambiente com nosso serviço de administração de Kubernetes. Expertise para garantir operações eficientes e seguras. Saiba mais agora!" />
                <meta name="keywords" content="sre, devops, observability, kubernetes, ansible, configuration manager, aws, gce, oci, azure" />
                <meta name="author" content="" />
                <meta property="og:title" content="O poder da engenharia de plataforma" />
                <meta property="og:description" content="Automatize processos e maximize a eficiência operacional." />
            </Helmet>             
            <Navbar/>
            <PageTitle pageTitle={'SRE confiabilidade de sites'} pagesub={'serviços-ti/sre'}/> 
            <Services srvClass={'wpo-service-section-s3'}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ServicesSrePage;
